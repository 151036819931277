var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');

function updateStatsigUser(data) {
    // Update the user object
    if (window.Statsig && data) {
        window.Statsig.StatsigClient.instance().updateUserSync({ userID: data.statsigUserID });
    }
}

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            if (window.addRecaptcha) {
                window.addRecaptcha('login', 'login');
            }
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        if (data.legacyCustomer) {
                            $('.regular-text').addClass('d-none');
                            $('.legacy-text').removeClass('d-none');
                            $('.password-reset').trigger('click');
                        }
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                        if (window.reloadRecaptcha) {
                            window.reloadRecaptcha();
                        }
                        $('body').trigger('gtm:failedLogin', { method: 'Email', errorMessage: data.error && data.error.length > 0 ? data.error[0] : '' });
                    } else {
                        $('form.login').trigger('login:success', data);
                        updateStatsigUser(data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    window.reloadRecaptcha();
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                    $('body').trigger('gtm:failedLogin', { method: 'Email', errorMessage: data.error && data.error.length > 0 ? data.error[0] : '' });
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            if (window.addRecaptcha) {
                window.addRecaptcha('registration', 'registration');
            }
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();

            $('[name$=_emailconfirm]', form).val($('[name$=_email]', form).val());
            $('[name$=_passwordconfirm]', form).val($('[name$=_password]', form).val());

            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                        if (window.reloadRecaptcha) {
                            window.reloadRecaptcha();
                        }
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        updateStatsigUser(data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (window.reloadRecaptcha) {
                        window.reloadRecaptcha();
                    }
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.confirm-reset-icon').show();
                        $('.request-password-title').text(data.receivedMsgHeading).css('text-align', 'center');
                        $('.request-password-body').empty()
                            .append('<p class="reset-confirm">' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    menuTabs: function () {
        $('.js-login-nav').on('click', function () {
            var $this = $(this);
            $this.toggleClass('active');

            if ($this.hasClass('active')) {
                $('.js-login-nav').not($this).removeClass('active');
                $('.login-form-nav .tab-content').not($this.next()).slideUp(500);
                $this.next().slideDown(500);
            } else {
                $this.next().slideUp(500);
            }
        });
    },

    showPassword: function () {
        $('.js-password-icon').on('click', function () {
            var $this = $(this);
            $this.toggleClass('show-password');

            if ($this.hasClass('show-password')) {
                $this.parent().find('input').attr('type', 'text');
            } else {
                $this.parent().find('input').attr('type', 'password');
            }
        });
    },

    loginBackBtn: function () {
        $('.js-historyback-login').on('click', function () {
            var url = $(this).data('url');
            if (url) {
                window.location.href = url;
            } else {
                window.history.back();
            }
        });
    },

    invalidCheckmark: function () {
        $('.registration .create-btn').on('click', function () {
            var accountPolicyText = $('.login-account-policy');
            var policyCheckbox = $('.checkbox-container input');

            if (policyCheckbox.is(':checked')) {
                accountPolicyText.removeClass('color-error');
                policyCheckbox.removeClass('is-invalid');
            } else {
                accountPolicyText.addClass('color-error');
            }
        });
    },

    cancelChangePassword: function () {
        $('.js-cancel-newpassword').on('click', function () {
            console.log('AWDawd');
            window.location.href = $(this).data('redirect');
        });
    }
};
